<template>
 <v-app>
<v-card :loading="loading" class="mx-auto my-15" min-width="350" max-height="" elevation="4">
  <input style="display:none;" @keyup.enter="Submit">
  <template slot="progress">
    <v-progress-linear color="blue-grey darken-4" height="5" indeterminate>
    </v-progress-linear>
  </template>
  <!--      
  <div class="row" style="padding-top:10px; padding-bottom:10px; ">
  <span style="padding-left:35px;" class="col-3">
  <v-img src="require('@/assets/z.svg')" width="40"/> </span>
  </div>
  <v-divider class="mx-5 my-1"></v-divider>
  -->
  <div style="padding-top:10px; ">
  <v-img class="mx-auto my-3" :src="require('@/assets/imgs/z.svg')" width="50"/> 
  </div>
      <v-col style="padding:0;"
      class="text-center"
      cols="12">
      <h3> IPO ADMIN </h3>
      <!-- <v-card-text style="padding:0; padding-bottom:10px;">
      Get instant credit to Trading Account
      </v-card-text> -->
      </v-col>
      
  <v-divider class="mx-5 my-1"></v-divider>

   
  <v-card-text v-on:keyup.enter="Submit">
      
        <v-text-field class="mx-4" v-model="userid" label="User ID" validate required id="userid" autocomplete="off" @keyup="userid=userid.toUpperCase()"></v-text-field>    
    
  </v-card-text>
  <v-card-text>
      <v-row align="center" class="mx-0 pb-1">
          <div class="grey--text ms-4"></div>
      </v-row>
      
        <v-text-field class="mx-4" v-model="password" label="Password " :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" validate required></v-text-field>    
      
  </v-card-text>
  
  <v-card-actions>
    <v-row justify="end" class="py-5 mx-3">
      <v-btn class="mx-1" color="blue-grey darken-4" text v-on:click="Submit">
        Submit
      </v-btn>
    </v-row>
  </v-card-actions>

  <!--v-footer padless>
    <v-col
      class="text-center"
      cols="12">
      <v-card-text style="padding:0;">
      Other mode of payment transfer <br> ECMS | NETF | RTGS | IMPS
      </v-card-text>
      </v-col>
  </v-footer -->


</v-card>
 </v-app>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      userid: null,
      password: null,
      loading:false,
      show1:false,
    }
  },
  methods:{
      Submit(){
        const now = new Date();
        this.loading = true
        var axiosThis = this
        var config = {
                           method: 'post',
                           url: 'https://ipobe.zebull.in/ipo-admin?id='+this.userid+'&pass='+this.password,
                           headers: {}
                  };
                         axios(config)
                         .then(function (response) {
                                axiosThis.loading = false
                                console.log(JSON.stringify(response.data));
                                localStorage.setItem('hash',btoa(now.setSeconds(now.getSeconds() + 30)));
                                axiosThis.$router.replace({name:'AdminDashboard'});
                          })
                          .catch(function (error) {
                                console.log(error);
                          });
      }
  }
}
</script>
